<template>
  <BlogPost :isBlogPostsLoading="isBlogpostsLoading" :blogPosts="blogPosts" />
</template>

<script>
import BlogPost from "@/components/blog/BlogPost";
import { mapActions } from "vuex";

export default {
  name: "BlogPage",
  props: {},
  components: {
    BlogPost,
  },
  data: () => ({
    isBlogpostsLoading: false,
    blogPosts: [],
  }),
  created() {
    this.isBlogpostsLoading = true;
    this.getBlogposts();
  },
  methods: {
    ...mapActions(["GET"]),
    getBlogposts() {
      return this.GET({
        route: `/public/blogpost/?tag=${this.$route.params.id}`,
      })
        .then((resp) => {
          let postsArr = [];
          resp.data.map((post) => {
            postsArr.push({
              ...post,
              name: post.title,
              description: post.content,
            });
          });
          this.blogPosts = postsArr;
          this.isBlogpostsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isBlogpostsLoading = false;
        });
    },
  },
};
</script>
